

body {
  width: 100vw;
  height: 100vh;
  margin: 0;
  background-color: hidden;
  overflow: hidden;
  font-family: IBMPlexSans;
}

@font-face {
  font-family: 'IBMPlexSans';
  src: url('../models/Font/IBMPlexSans-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

.heading {
  position: relative;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 22px;
  transition-duration: 0.4s;
  cursor: pointer;
  font-family: IBMPlexSans;
  font-style: regular;
  width: 90%;
}

h1 {
  font-family: 'IBMPlexSans';
  font-size: 24px;
  font-weight: 400;
  font-style: normal;
  text-transform: uppercase;
}

#myVideo {
  position: absolute;
  right: 0;
  bottom: 0;
  min-height: 100%;
  min-width: 100%;
  z-index: -1;
}

.header {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  padding-bottom: 0px;
  background-color: black;
  text-align: center;
  z-index: 100;
  opacity: 0.9;
}

.footer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 30px;
  background-color: black;
  text-align: center;
  z-index: 100;
  opacity: 0.9;
}

input#start {
  top: -20px;
  border: none;
  color: white;
  background-color: black;
  border: 1px solid white;
  padding: 8px 28px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 22px;
  margin: 0px 40px;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 30px;
  font-family: IBMPlexSans;
  font-style: regular;
}


input#reset {
  position: relative;
  top: 17px;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  border: 1px solid white;
  background-image: url(/src/models/zuruck.svg); /* 10px x 10px */
  background-color: black; /* make the button transparent */  
  background-repeat: no-repeat;  /* make the background image appear only once */
  background-position: 7px 7px;  /* equivalent to 'top left' */
  background-size: 70%;

}

input#reset:hover {

  border: 0px solid ;
  background-image: url(/src/models/zuruckh.svg); /* 10px x 10px */
  background-color: white; /* make the button transparent */  
  background-repeat: no-repeat;  /* make the background image appear only once */
  background-position: 7px 7px;  /* equivalent to 'top left' */
  background-size: 70%;
;
}


input#start:hover {
  background-color: white;
  color: black;
}


#myAnimation {
  width: 50px;
  height: 50px;
  position: absolute;
  background: red;
}

#overlay {
  position: absolute;
  background-color: red;
  opacity: 0.5;
  height: 500px;
  width: 800px;
}

/* body {
	background-color: #f2f2f2 !important;
   margin: 30px !important;
}
* {
  transition: all .2s ease;
} */


.extra-info {
  position: fixed;
  display: none;
  background-color: transparent;
  line-height: 30px;
  font-size: 20px;
  top: 20px;
  left: 50px;
  padding: 10px 10px 10px 10px;
  z-index: 100;
  text-align: left !important;
  color: white;
  font-family: IBMPlexSans;
  font-style: regular;
  transition: 200ms;
  /* text-transform: uppercase; */
}

.info:hover .extra-info {
  display: block;
}

.info {
  position: absolute;
  font-size: 30px;
  margin: 21px;
  margin-left: 21px;
  padding: 10px;
  height: 35px;
  width: 35px;
  background-color: red;
  border-radius: 50%;
}

.info:hover {
  position: absolute;
  background-color: black;
  width: 380px;
  height: 400px;
  border-radius: 50px;
  text-align: left !important;
  transition-duration: 200ms;
}